import { state } from ":mods";
import { getMaterialDetails, getSubModuleDetails } from "../../apis";
import { Match, Switch, createEffect, createRenderEffect } from "solid-js";
import { createSubmoduleInputs } from "../../forms";
import { createMaterialInputs } from "../../forms/material";

export function MaterialForm({
  materialID,
  buttonMsg,
  onDone,
}: {
  materialID: number | string;
  buttonMsg?: string;
  onDone?: (values: ReturnType<ReturnType<typeof createMaterialInputs>["Actions"]["getValuesSnakecase"]>) => void;
}) {
  const $details = state.createAsync([getMaterialDetails, materialID], { manualFetch: { startSuccess: true } });
  const Form = createMaterialInputs();
  if (materialID !== undefined) {
    console.log("refetch material :: ", materialID);
    $details.on((event, { value }) => {
      if (materialID) {
        console.log("sad :: ", value, " :: ", materialID);
        Form.Actions.update(value.data as any);
      }
    });
    $details.refetch();
  }

  function onSubmit() {
    const values = Form.Actions.getValuesSnakecase();

    if (onDone) {
      onDone(values);
    }
  }
  return (
    <Switch fallback={<div>unknown resource state</div>}>
      <Match when={$details.state.loading}>Loading Module Details...</Match>
      <Match when={$details.state.failed}>An error occured while loading module details {}...</Match>
      <Match when={$details.state.success}>
        <div class="w-full h-full flex flex-col">
          <Form.ID.Label title="ID" />
          <Form.ID.Input class="form-input" type="text" />
          <Form.DesignName.Label title="Design Name" />
          <Form.DesignName.Input class="form-input" type="text" />
          <Form.Name.Label title="Name" />
          <Form.Name.Input class="form-input" type="text" />
          <Form.ShortDescription.Label title="Short Description" />
          <Form.ShortDescription.Input class="form-input" type="text" />
          <Form.Description.Label title="Description" />
          <Form.Description.Input class="form-input" type="text" />
          <Form.Length.Label title="Length" />
          <Form.Length.Input class="form-input" type="text" />
          <Form.MaxElements.Label title="Max Elements" />
          <Form.MaxElements.Input class="form-input" type="number" />
          <Form.Order.Label title="Order" />
          <Form.Order.Input class="form-input" type="number" />

          {/* <Form.Submodule.Label title="Name" /> */}
          {/* <Form.Submodule.Input class="form-input" type="text" /> */}

          <Form.Actions.Button
            class="form-btn !w-fit !min-h-50px px-2 mt-2"
            statusValid={buttonMsg ?? "Create Material"}
            onSubmit={onSubmit}
            onlyStatus={["valid"]}
          />
        </div>
      </Match>
    </Switch>
  );
}
